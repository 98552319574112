

















































































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import Button from '@/app/ui/components/Button/index.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import DataTableV2 from '@/app/ui/components/DataTableV2/index.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import Modal from '@/app/ui/components/Modal/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import WarningIcon from '@/app/ui/assets/icon_warning_circle.vue'
import SuccessIcon from '@/app/ui/assets/success_icon_alt.vue'
import DownloadIcon from '@/app/ui/assets/icon_download_filled.vue'
import BackIcon from '@/app/ui/assets/icon_back.vue'
import EmptyIllustration from '@/app/ui/assets/EmptyState/no_data-paket.vue'
import EmptyFilterIllustration from '@/app/ui/assets/EmptyState/no_filtered_data.vue'
import ErrorIcon from '@/app/ui/assets/illustration_confirmation_delete.vue'
import ModalUploadBulky from '../../components/Modals/ModalUploadBulky/index.vue'
import ModalConfirm from '../../components/Modals/ModalConfirm/index.vue'
import ModalFailed from '../../components/Modals/ModalFailed/index.vue'
import ModalSuccess from '../../components/Modals/ModalSuccess/index.vue'
import {
  EnumAlertMessage,
  EnumInvalidReason,
  EnumStatusUpload,
  IDataCell,
  IFileData,
  IHeaderCell,
  IOptions,
  IUploadBulkyResParams,
} from '@/app/infrastructures/misc/Constants/manualAdjustSaldo'
import controller from '@/app/ui/controllers/ManualAdjustmentBalanceController'
import { EventBusConstants, Utils } from '@/app/infrastructures/misc'
import {
  UploadBulkBalanceAmount,
  UploadBulkRow,
  UploadBulkSummary,
} from '@/domain/entities/ManualAdjustmentBalance'

interface UploadBulkData {
  dataUpload?: {
    filename?: string
    summary?: UploadBulkSummary
    rows?: Array<UploadBulkRow>
    pagination?: {
      page: number
      perPage: number
      totalItem: number
    }
  }
}

@Component({
  components: {
    Button,
    DropdownSelect,
    DataTableV2,
    PaginationNav,
    Modal,
    LoadingOverlay,
    WarningIcon,
    SuccessIcon,
    DownloadIcon,
    BackIcon,
    ErrorIcon,
    EmptyIllustration,
    EmptyFilterIllustration,
    ModalUploadBulky,
    ModalConfirm,
    ModalFailed,
    ModalSuccess,
  },
})
export default class ManualAdjustSaldoUploadBulkyPage extends Vue {
  controller = controller
  enumAlertMessage = EnumAlertMessage
  enumInvalidReason = EnumInvalidReason

  modalUploadBulkyVisible = false
  modalConfirmReuploadVisible = false
  modalConfirmReturnVisible = false
  modalFailedConnectionVisible = false
  modalFailedConnectionSubmitVisible = false
  modalFailedNameExistVisible = false
  modalSuccessVisible = false

  paginationOptions: Array<IOptions> = [
    { label: '10', value: 10 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
  ]

  statusOptions: Array<IOptions> = [
    { label: 'Semua', value: '' },
    { label: 'Valid', value: 'VALID' },
    { label: 'Tidak Valid', value: 'INVALID' },
  ]

  parameters: IUploadBulkyResParams = {
    page: 1,
    per_page: 10,
    status: this.statusOptions[0],
  }

  headers: Array<string | IHeaderCell> = [
    this.headerCellMapper('Baris', '7%'),
    this.headerCellMapper('Kurir ID', '13%'),
    this.headerCellMapper('Jumlah', '15%'),
    this.headerCellMapper('Catatan', '50%'),
    this.headerCellMapper('Status', '15%'),
  ]

  fileData: IFileData = {
    filename: '',
    rowValid: NaN,
    rowInvalid: NaN,
    courierValid: NaN,
    courierInvalid: NaN,
    amountValid: {
      addition: NaN,
      reduction: NaN,
    },
    amountInvalid: {
      addition: NaN,
      reduction: NaN,
    },
  }

  tableData: Array<Array<string | number | IDataCell>> = []

  created() {
    if (this.$route.name === 'UploadBulkyManualAdjustSaldoList') {
      this.fetchUploadBulkData(true)
    }
  }

  mounted() {
    this.onSetDisplayBreadcrumb(false)
    window.addEventListener('offline', this.onHandleOffline)
  }

  get isAllValid(): boolean {
    if (this.fileData.rowInvalid || this.fileData.courierInvalid) {
      return false
    }

    return true
  }

  get params(): Record<string, string | number> {
    return {
      page: this.parameters.page,
      per_page: this.parameters.per_page,
      filter: this.parameters.status.value,
    }
  }

  get linkSourceFile(): string | undefined {
    return controller.dataUploadBulky.dataUpload?.filepath
  }

  private fetchUploadBulkData(reset?: boolean): void {
    if (reset) this.parameters.page = 1
    controller.getListUploadBulk(this.params)
  }

  private onHandleOffline(): void {
    if (controller.isLoadingSubmitBulk) {
      controller.setLoadingSubmitBulk(false)
      this.modalFailedConnectionSubmitVisible = true
      return
    }

    if (this.modalUploadBulkyVisible) {
      this.modalUploadBulkyVisible = false
      this.modalFailedConnectionVisible = true
    }
  }

  private onChangePaginationOption(perPageAmount: number): void {
    this.parameters.per_page = perPageAmount
    this.fetchUploadBulkData(true)
  }

  private onClickReupload(isAllValid: boolean): void {
    if (isAllValid) {
      this.modalConfirmReuploadVisible = true
    } else {
      this.onConfirmReupload()
    }
  }

  private onConfirmReturn(): void {
    this.$router.push({ name: 'ManualAdjustSaldoList' })
  }

  private onConfirmReupload(): void {
    if (this.$route.name === 'UploadBulkyManualAdjustSaldoList') {
      this.$router.push({ name: 'UploadBulkyManualAdjustSaldo' })
    }
    controller.setErrUploadBulky('')

    this.modalConfirmReuploadVisible = false
    this.modalFailedNameExistVisible = false
    this.onResetState()
  }

  private onResetState(): void {
    this.tableData = []
    this.fileData = {
      filename: '',
      rowValid: NaN,
      rowInvalid: NaN,
      courierValid: NaN,
      courierInvalid: NaN,
      amountValid: {
        addition: NaN,
        reduction: NaN,
      },
      amountInvalid: {
        addition: NaN,
        reduction: NaN,
      },
    }
    this.parameters = {
      page: 1,
      per_page: 10,
      status: this.statusOptions[0],
    }
  }

  private onFailedUpload(): void {
    this.modalFailedConnectionVisible = false
    this.onResetState()
  }

  private onDownloadFailed(): void {
    if(this.linkSourceFile) window.open(process.env.VUE_APP_STORAGE_BASE_URL+'/'+this.linkSourceFile)
  }

  private onSubmit(): void {
    controller.submitBulk()
  }

  private onSetDisplayBreadcrumb(isDisplayed: boolean): void {
    const breadcrumb = document.getElementById('breadcrumb')
    if (breadcrumb) {
      if (isDisplayed) {
        breadcrumb.style.display = 'flex'
      } else {
        breadcrumb.style.display = 'none'
      }
    }
  }

  private tableCellMapper(
    value: string | number | boolean,
    colWidth: string
  ): IDataCell {
    return {
      value: value,
      customStyle: {
        minWidth: colWidth,
        maxWidth: colWidth,
      },
    }
  }

  private headerCellMapper(
    title: string | number,
    colWidth: string
  ): IHeaderCell {
    return {
      title: title,
      customStyle: {
        minWidth: colWidth,
        maxWidth: colWidth,
      },
    }
  }

  private formatAmount(amount?: number): string {
    if (amount) {
      if (amount < 0) {
        return `-Rp${(amount * -1).toLocaleString('id-ID')}`
      } else if (amount >= 0) {
        return `+Rp${amount.toLocaleString('id-ID')}`
      }
    }

    return '0'
  }

  private headerDataMapper(
    filename: string,
    summary: UploadBulkSummary,
    amountValid: UploadBulkBalanceAmount,
    amountInvalid: UploadBulkBalanceAmount
  ): IFileData {
    const valMapper = (data?: number) => (data !== undefined ? data : NaN)

    return {
      filename: filename || '',
      rowValid: valMapper(summary.rowValid),
      rowInvalid: valMapper(summary.rowInvalid),
      courierValid: valMapper(summary.courierValid),
      courierInvalid: valMapper(summary.courierInvalid),
      amountValid: {
        addition: valMapper(amountValid.addition),
        reduction: valMapper(amountValid.reduction),
      },
      amountInvalid: {
        addition: valMapper(amountInvalid.addition),
        reduction: valMapper(amountInvalid.reduction),
      },
    }
  }

  private tableDataMapper(
    data: Array<UploadBulkRow>
  ): Array<Array<string | number | IDataCell>> {
    return data.map((item, index) => {
      let amount = '0'

      if (
        item.reason === this.enumInvalidReason.NON_INTEGER_AMOUNT_DESC ||
        item.reason === this.enumInvalidReason.ZERO_AMOUNT_DESC
      ) {
        amount = item.amount ? String(item.amount) : '0'
      } else {
        amount = this.formatAmount(Number(item.amount))
      }

      return [
        this.tableCellMapper(
          (this.parameters.page - 1) * this.parameters.per_page + index + 1,
          '7%'
        ),
        this.tableCellMapper(String(item.courierId), '13%'),
        this.tableCellMapper(amount, '15%'),
        this.tableCellMapper(item.note || '', '50%'),
        this.tableCellMapper(item.reason || item.status || '', '15%'),
      ]
    })
  }

  @Watch('controller.dataUploadBulky')
  setDataUploadBulky(data: UploadBulkData): void {
    if (!data.dataUpload) return

    const summary = data.dataUpload.summary
    const rows = data.dataUpload.rows
    const pagination = data.dataUpload.pagination

    if (summary && summary.amountValid && summary.amountInvalid) {
      this.fileData = this.headerDataMapper(
        data.dataUpload.filename || '',
        summary,
        summary.amountValid,
        summary.amountInvalid
      )
    }

    if (rows) {
      this.tableData = this.tableDataMapper(
        rows.slice(0, this.parameters.per_page)
      )
    }

    if (pagination) {
      controller.setDataUploadPaginationBulky(pagination)
    } else {
      if (rows) {
        controller.setDataUploadPaginationBulky({
          page: this.parameters.page,
          perPage: this.parameters.per_page,
          totalItem: rows.length,
        })
      }
    }
  }

  @Watch('controller.errUploadBulky')
  onCheckErrUploadBulku(msg: string): void {
    if (msg !== '') {
      if (msg === EventBusConstants.UPLOAD_BULKY_MANUAL_ADJUSTMENT_BALANCE) {
        Utils.debounce(() => {
          this.controller.setStatusUpload(EnumStatusUpload.START)
          this.modalUploadBulkyVisible = false
          this.$router.push({
            name: 'UploadBulkyManualAdjustSaldoList',
          })
        }, 400)()
      } else {
        Utils.debounce((response: string) => {
          this.controller.setStatusUpload(EnumStatusUpload.START)
          if (response.includes('is already uploaded')) {
            this.modalUploadBulkyVisible = false
            this.modalFailedNameExistVisible = true
          }
        }, 400)(msg)
      }
      this.controller.setErrUploadBulky('')
    }
  }

  @Watch('controller.errSubmitBulky')
  onCheckErrSubmitBulky(msg: string): void {
    if (msg === EventBusConstants.SUBMIT_BULKY_MANUAL_ADJUSTMENT_BALANCE) {
      this.modalSuccessVisible = true
    }
    controller.setErrSubmitBulky('')
  }

  beforeDestroy() {
    this.onSetDisplayBreadcrumb(true)
    window.removeEventListener('offline', this.onHandleOffline)
  }
}
